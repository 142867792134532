var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',{staticClass:"lahan-gis-verification"},[_c('div',{staticClass:"d-flex flex-row mb-3",staticStyle:{"justify-content":"space-between"}},[_c('h5',[_vm._v("Verifikasi Data")]),_c('button',{on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_vm._l((_vm.questions),function(question,i){return _c('v-col',{key:`quest-${i}`,attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
              label: question.question + '?',
              type: 'select-radio',
              validation: ['required'],
              option: {
                default_options: _vm.defaultData.question_option,
                list_pointer: {
                  label: 'name',
                  code: 'code',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData[`question_${question.id}`]),callback:function ($$v) {_vm.$set(_vm.formData, `question_${question.id}`, $$v)},expression:"formData[`question_${question.id}`]"}})],1)}),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"w-100",staticStyle:{"width":"100%"},attrs:{"type":"submit","variant":"warning","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Verifikasi Data")])],1)],1)],2)],1)]}}],null,false,3997082832)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }